import { Link } from "gatsby";
import React from "react"

const searchBanner = ({
  pageTitle,
  title,
  mainDropdown,
  secondaryDropdown
}) => {
  return (
    <div
      className={`text-white bg-cover bg-no-repeat h-full  `}
      style={{
        backgroundImage: `url("https://d2lxkizvrhu4im.cloudfront.net/images/partners_top_banner_bg.svg")`,
      }}
    >
      <div className=" flex flex-col justify-center items-center py-10">
        <div className={`w-full lg:w-10/12  mx-auto md:mx-0 lg:mx-auto space-y-10`}>
          <div className={`text-xs md:text-base font-semibold opacity-70 text-center my-10`}>
            {pageTitle}
          </div>
          <div className={`text-3xl md:text-6xl text-center font-bold `}>
            {title}
          </div>
          <div className="h-28 py-6">
            <div className=" w-10/12 h-14 mx-auto bg-white  bg-opacity-50 md:bg-opacity-100  rounded-lg box-border flex flex-row items-center justify-between ">
              <div className="flex flex-row items-center w-7/12">
                <img
                  alt="subuscribe page img"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/Search_icon.svg"
                  className="h-5 m-6 md:m-2 lg:m-6 "
                />

                <input
                  placeholder="Trouver une application"
                  className="   bg-transparent  focus:outline-none text-black  opacity-50"
                />
              </div>
              <img
                alt="filter icon img"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/setting_icon_white.svg"
                className="h-8 m-6 md:m-2 lg:m-6 flex cursor-pointer md:hidden"
              />

              <div className={`w-2/6 space-x-7 px-2 relative bg-white h-full rounded-r-lg text-black font-bold md:text-sm text-center  hidden md:flex flex-row items-center justify-center cursor-pointer hover:shadow-lg space-x-4 mx-2`}>
                <div className=" group border-0 focus:outline-none cursor-pointe w-1/2">
                  <div className="relative flex flex-row items-center justify-between text-gray-600 font-normal">
                    <span>{mainDropdown.name}</span>
                    <img
                      alt="header"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="my-0  "
                    />
                  </div>
                  <div className="absolute hidden group-hover:block z-50">
                    <div className="flex flex-col overflow-visible bg-white rounded-b-lg font-normal space-y-2 p-5 shadow-lg">
                      {
                        mainDropdown?.list?.map((item, index) => (
                          <Link to="">{item.name}</Link>
                        ))
                      }
                    </div>
                  </div>
                </div>

                <div className=" group border-0 focus:outline-none cursor-pointer w-1/2 ">
                  <div className="relative flex flex-row justify-between items-center space-x-2 text-gray-600 font-normal">
                    <span>{secondaryDropdown.name}</span>
                    <img
                      alt="header"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="my-0 h-2 "
                    />
                  </div>
                  <div className="absolute hidden group-hover:block z-50">
                    <div className="flex flex-col overflow-visible bg-white rounded-b-lg font-normal space-y-2 p-5 shadow-lg">
                      {
                        secondaryDropdown?.list?.map((item, index) => (
                          <Link to="">{item.name}</Link>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button className=" md:hidden  w-11/12 h-14 mx-auto bg-white   text-center   rounded-lg box-border flex flex-row items-center my-10 font-bold focus:outline-none">
            <span className="mx-auto text-yellow-500 text-lg">
              Search
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default searchBanner;
