import React, {useState, useEffect} from "react"
import Company from "./company"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
const Ecommerce=({title})=>{

    const DataArray=[
        {iconSrc:"Leaky Paywall.svg", title:"Leaky Paywall", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular","Sca-Ready"]},
        {iconSrc:"Zoho inventory.svg", title:"Zoho inventory", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular","Sca-Ready"]},
        {iconSrc:"WooCommerce.svg", title:"WooCommerce", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular"]},
        {iconSrc:"Squarespce.svg", title:"Squarespce", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular"]},
        {iconSrc:"Commerce Sync.svg", title:"Commerce Sync", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular"]},
        {iconSrc:"Craftpeak.svg", title:"Craftpeak", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular"]},
        {iconSrc:"Mailchimp Websites.svg", title:"Mailchimp Websites", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular","Sca-Ready"]},
        {iconSrc:"SuperPay.svg", title:"SuperPay", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular","Sca-Ready"]},
        {iconSrc:"Commerce Sync.svg", title:"Commerce Sync", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular"]},
        {iconSrc:"SuperPay.svg", title:"SuperPay", description:"Question setting made simple with ready-to-go authoring tools", tags:["Popular","Sca-Ready"]}]
        const breakpoint = useBreakpoint()
        const [size, setSize]=useState(3)
        useEffect(()=>{
            breakpoint.md?setSize(3):setSize(4);
        },[breakpoint])
        const SlicedArray=DataArray.slice(0,size)
      
        



return(
    <>
  
   <div className="w-full my-20">
       
       <div className="text-3xl text-center font-bold md:font-semibold">
       {title}
       </div>
       <div className="md:hidden text-sm font-normal text-gray-500 text-center">
       Get 100+ features out of the box with Stripe’s integrated per-transaction pricing
       </div>
       <div className="flex flex-col md:flex-row flex-wrap my-7">
           {   
               SlicedArray.map((Data, index)=> <div className="w-full md:w-1/2 lg:w-1/4 my-4" key={index}>
               <Company iconSrc={Data.iconSrc} title={Data.title} description={Data.description} tags={Data.tags}/>
                </div>
               )}
           
           
       </div>
       {/* <div className="text-yellow-500 font-bold text-center my-10 flex flex-row items-center justify-center space-x-1 cursor-pointer" onClick={()=>setSize(DataArray.length)}>
           <span>See All</span> <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg" className="m-0 transform  -rotate-90 w-3" alt="arrow icon" />
       </div> */}
   </div>
    </>
)
}

export default Ecommerce