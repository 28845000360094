import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";

import SubscribeHBSPT from "./subscribe_hbsp";
import { useTranslation } from 'react-i18next';

const Subscribe = () => {
  const breakpoints = useBreakpoint();
  const {t} = useTranslation();
  return (
    <>
      <div
        className=" text-white flex flex-row items-center bg-cover md:bg-contain h-full  justify-center bg-no-repeat my-0 "
        style={{
          backgroundImage: `${
            breakpoints.md
              ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/work-better_bg.svg")`
              : `url("https://d2lxkizvrhu4im.cloudfront.net/images/bg2.svg")`
          }`,
          // backgroundSize:'100%'
        }}
      >
        <div className="text-white flex flex-col md:flex-row justify-center items-center w-full my-10 md:mt-10 md:mb-0">
          <div className=" hidden lg:flex ">
            <img alt="subuscribe page img" src="https://d2lxkizvrhu4im.cloudfront.net/images/message_girl.svg" />
          </div>

          <div className="w-full p-5 md:p-10 md:w-1/2 flex flex-col items-center lg:items-start mx-auto space-y-4">
            <div className="text-3xl md:text-4.5xl font-thin">
              <span className="font-semibold">{t('our_brochure')}</span>
            </div>
            <div className="font-normal text-sm md:text-lg text-center md:text-left">
              {t('receive_our_new_brochure_with_details')}
            </div>
            <div className="text-base w-10/12 text-center lg:text-start subscribe_results_container"></div>
            <div className="w-full h-14 bg-white bg-opacity-25 rounded-lg box-border flex flex-row items-center subscribe_static_hide">
              <img
                alt="subuscribe page img"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_message_icon.svg"
                className="h-5 m-6 md:m-2 xl:m-6 "
              />
              <input
                id="subscribe_email_static"
                placeholder="E-mail"
                className="w-5/6 font-normal text-sm md:text-lg border-l-2 border-white bg-transparent placeholder-white px-5 focus:outline-none"
              />
              <div className="subscribe_submit_static bg-white hidden md:flex h-full rounded-r-lg text-black font-semibold lg:text-xl text-center w-3/6 flex-col justify-center ">
                <button className=" focus:outline-none">{t('send')} </button>
              </div>
            </div>
            <div className="subscribe_static_hide subscribe_submit_static bg-white md:hidden flex h-14 rounded-lg text-black font-bold lg:text-sm text-center w-full flex-col justify-center ">
              <button className=" focus:outline-none">{t('send')} </button>
            </div>
            <SubscribeHBSPT />
          </div>
          <div className="flex flex-col w-full md:w-1/2 my-4 xl:my-10 border-l-2 border-yellow-400 md:pl-10 space-y-10">
            <div className="w-full space-y-2 ">
              <div className=" text-1.5xl font-bold text-center flex flex-row justify-center md:justify-start md:text-left">
                {t('visit_our_blog')}
              </div>
              <div className="text-sm md:text-lg font-medium text-center md:text-left w-5/6 md:w-full mx-auto opacity-50">
                {t('discover_the_latest_trends')}
              </div>
              <div
                className="text-1.5xl font-bold text-center flex flex-row justify-center md:justify-start md:text-left"
                style={{ cursor: "pointer" }}
              >
                <Link to="https://mereos.eu/blog/">{t('discover')} </Link>
                <img
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                  className="m-0 transform rotate-90 ml-1"
                  alt="arrow icon"
                />
              </div>
            </div>
            <div className="w-full space-y-2">
              <div className="text-1.5xl font-bold text-center flex flex-row justify-center md:justify-start md:text-left">
                {t('browse_our_help_center')}
              </div>
              <div className="text-sm md:text-lg font-medium text-center md:text-left w-5/6 md:w-full mx-auto opacity-50">
                {t('see_answers_to_the_most_frequently')}
              </div>
              <Link
                to="https://mereos.zendesk.com"
                className="text-1.5xl font-bold text-center flex flex-row justify-center md:justify-start md:text-left "
              >
                <div
                  className="text-1.5xl font-bold text-center flex flex-row justify-center md:justify-start md:text-left "
                  style={{ cursor: "pointer" }}
                >
                  {t('to_consult')}{" "}
                  <img
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                    className="m-0 transform rotate-90 ml-1"
                    alt="arrow icon"
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className=" hidden lg:flex ">
            <img alt="subuscribe page img" src="https://d2lxkizvrhu4im.cloudfront.net/images/message_girl_2.svg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
