import * as React from "react"

import PartnerComponent from "../components/partner/partners";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";

const Partner = () => {
  return (
    <>
      <Seo
        title="Partenaires"
        description="Partenaires"
      />
      <Layout>
        <PartnerComponent />
      </Layout>
    </>
  )
}

export default Partner
