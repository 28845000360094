import React from "react";
import { Link } from "gatsby";

import SearchBanner from "../top_banner/search-banner";
import Ecommerce from "../ecommerce/ecommerce";
import Subscribe2 from "../subscribe/subscribe_2";

const Partners = () => {
  return (
    <div className="font-sans">
      <SearchBanner
        pageTitle="PARTNERS"
        title=" Gérer vos sessions de recrutement en ligne avec Mereos et ses partenaires"
        mainDropdown = {{
          name: 'Catégorie',
          list: [
            {name: 'About Us'},
            {name: 'Partners'},
            {name: 'Contact'}
           ]
        }}
        secondaryDropdown = {{
          name: 'Lieu',
          list: [
            {name: 'Ecole 1'},
            {name: 'Ecole 2'},
            {name: 'Ecole 3'}
           ]
        }}
      />
      <div className={`conatiner font-sans`}>
        <div className={`mx-auto w-10/12 lg:w-5/6 `}>
          <Ecommerce title="Plateformes d’évaluation" />
          <Ecommerce title="Plateformes d’apprentissage" />
          <Ecommerce title="SIRH" />
          <Ecommerce title="Centre de formation en ligne" />
          <Ecommerce title="Agence de contenu spécialisé" />
        </div>
        <Subscribe2 />
      </div>
    </div>
  );
};

export default Partners;
