import React from "react"

const Company =({iconSrc, title, description,  tags})=>{


    return(
        <div className="w-full flex flex-row md:flex-col space-x-4 md:space-x-0 md:space-y-4">
        <div className="w-1/4 md:w-full">
            <img src={`/icons/${iconSrc}`} className="m-0 h-16 md:h-12" alt="icon"/>
        </div>
        <div className="space-y-3">
        <div className="font-bold md:font-semibold text-sm md:text-lg">
            {title}
        </div>
        <div className="text-xs md:text-sm text-gray-500 w-10/12  font-light">
            {description}
        </div>
        <div className="flex flex-row space-x-2">
        
            {tags.map((tag, index)=><div className=" border-2 font-semibold border-gray-50 min-w-min px-3  rounded-3xl text-xxs flex flex-col justify-center items-center h-7 ">
                {tag}
            </div>
            )}
        </div>
        </div>
        </div>
    )
}

export default Company