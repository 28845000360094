import React from "react";

export default class SubscribeHBSPT extends React.Component {
    componentDidMount() {
        const formId = 'd7c47dce-c496-44ff-a4e7-2dbe72c57d6e';

        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
            if(window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '20290290',
                    formId,
                    cssRequired: '',
                    target: '#SubscribeHBSPT',
                    translations: {
                        fr: {
                            forbiddenEmailDomainGeneric: "Veuillez utiliser votre adresse mail professionnelle",
                            forbiddenEmailDomain: "Veuillez utiliser votre adresse mail professionnelle",
                        }
                    }
                });
            }
        });

        window.addEventListener('message', event => {
            let innerDoc;
            const getIframeDoc = () => {
                const iframe = document.querySelector('#SubscribeHBSPT > iframe');
                innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            }

            const formResultsContainer = document.querySelector('.subscribe_results_container');

            const formMessagesDuplicator = function () {
                getIframeDoc();

                const issuesList = innerDoc.querySelector('.hs-email .hs-error-msgs');
                const successMessage = innerDoc.querySelector('.submitted-message');
                
                if (issuesList) {
                    formResultsContainer.innerHTML = issuesList.outerHTML;
                } else if (successMessage) {
                    formResultsContainer.innerHTML = successMessage.outerHTML;

                    const hideForm = document.querySelectorAll('.subscribe_static_hide');
                    hideForm.forEach((item) => {
                        item.style.display = 'none';
                    })
                } else {
                    formResultsContainer.innerHTML = '';
                }
            }
            
            const emailInputStatic = document.querySelector('input[id="subscribe_email_static"]');
            const buttonStatic = document.querySelectorAll('.subscribe_submit_static');
            if (event.data.type === 'hsFormCallback' && event.data.id === formId) {
                if (event.data.eventName === 'onFormReady') {
                    getIframeDoc();

                    const doEvent = ( obj, name ) => {
                        const event = new Event( name, { target: obj, bubbles: true });
                        return obj ? obj.dispatchEvent(event) : false;
                    }
    
                    const emailInputHbspt = innerDoc.querySelector('.hs_email input');
                    const formHbspt = innerDoc.querySelector('form');
    
    
                    emailInputStatic.addEventListener('keyup', () => {
                        emailInputHbspt.value = emailInputStatic.value;
                        doEvent(emailInputHbspt, 'change');
                        doEvent(emailInputHbspt, 'input');
                        doEvent(emailInputHbspt, 'blur');
                    });
    
                    buttonStatic.forEach((item) => {
                        item.addEventListener('click', () => {
                            doEvent(formHbspt, 'submit');
                            setTimeout(formMessagesDuplicator, 20);
                        });
                    });
                } else if (event.data.eventName === 'onFormSubmitted') {
                    formMessagesDuplicator();
                }
            }
        });
    }

    render() {
        return (
            <>
                <div style={{opacity: 0, visibility: "hidden", position: "absolute"}} id="SubscribeHBSPT"></div>
            </>
        );
    }
}